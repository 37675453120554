import React from "react"
import { Layout } from "../components/layout/Layout"
import { Home } from "../components/pages/home/Home"

export default function HomePage() {
  return (
    <Layout>
      <Home />
    </Layout>
  )
}
