import * as React from "react"

export function Home() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 upsideDown">
            <h1 className="mb-5 text-center">
              <b>„Betrachte die Dinge mal anders“</b>
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <div>
              „Manchmal müssen wir einfach den Blickwinkel ändern, um all das
              Wunderbare um uns herum zu erkennen!“
            </div>
            <div className="tiny">(Quelle: unbekannt)</div>
          </div>
        </div>
      </div>
      <p className="mt-3">
        <strong className="fs-4">STOP</strong> – Halte mal inne – gehe aus der
        Situation raus – verändere den Blickwinkel und finde wieder wer DU bist
        und sein möchtest!
      </p>
      <p>Die Verbindung zu DIR = der Weg zu DIR,</p>
      <p>
        darin möchte ich dich hier im Verbindungsraum begleiten &amp;
        unterstützen.
      </p>
      <p>
        In schwierigen Zeiten, Momenten, Phasen, Situationen kann man sich
        verlieren und für sich das Licht nicht mehr erkennen. Diese Zeiten,
        Momenten, Phasen und Situationen könnten die beste Chance zum Weg zu DIR
        sein!
      </p>
      <p className="fw-bold m-0">Finde deine Verbindung wieder</p>
      <b className="fs-1">{"\u221e"}</b>
      <ul className="p-0 mb-3 dashed">
        <li>zu DIR &amp; deinem Herzen</li>
        <li>zu deinem Potenzial &amp; Möglichkeiten</li>
        <li>zu deinen Schatten &amp; verborgenen Schätzen dahinter</li>
        <li>zu deiner Verwundbarkeit = deiner persönlichen Superkraft</li>
        <li>zu deiner Eigenmacht</li>
        <li>zu deiner Life Force</li>
        <li>zu deinem Licht</li>
        <li>und noch vielem Schönen mehr...</li>
      </ul>
      <p>
        Mein Zugang in der Beratung ist es meinen Erfahrungsschatz sowie Wissen,
        Techniken und Sensitivität miteinander zu verbinden, um dich in
        „krisenbehafteten Zeiten“ mit gesamtem Potenzial zu begleiten &amp;
        unterstützen, damit <b>DU</b> gestärkt mit neuen Blickwinkeln
        hervorgehen kannst mit der unverhandelbaren Verbindung zur <b>DIR</b>!
      </p>
    </>
  )
}
